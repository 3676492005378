<template>
  <!--
        列表展示
    -->
  <div class="table-responsive">
    <table class="table table-bordered">
      <thead>
        <tr class="th">
          <th>省(自治区)</th>
          <th>县(市)</th>
          <th>采样地点</th>
          <th>标本类型</th>
          <th>俗名</th>
          <th>发生分类土类</th>
          <th>发生分类土纲</th>
          <th>调查人</th>
          <th>野外调查日期</th>
          <th>标本提供者</th>
          <th>剖面标本管理者</th>
          <th>标本制作日期</th>
          <th>储存柜号</th>
          <th>储存屉号</th>
          <th>剖面深度记录</th>
          <th>标本保存地点</th>
          <th>分类鉴别单位</th>
          <th class="text-center">操作</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="item in $store.state.chinaList" :key="item.profile_id">
          <td>{{ item.province }}</td>
          <td>{{ item.county }}</td>
          <td>{{ item.location }}</td>
          <td>{{ item.specimens_type }}</td>
          <td>{{ item.local_soil_name }}</td>
          <td>{{ item.csgc_group }}</td>
          <td>{{ item.csgc_order }}</td>
          <td>{{ item.survey_staff }}</td>
          <td>{{ item.survey_date }}</td>
          <td>{{ item.provider }}</td>
          <td>{{ item.profile_manager }}</td>
          <td>{{ item.profile_monolith_date }}</td>
          <td>{{ item.storage_item }}</td>
          <td>{{ item.storage_row }}</td>
          <td>{{ item.profile_depth_rec }}</td>
          <td>{{ item.profile_monolith_location }}</td>
          <td>{{ item.charge_enterprise }}</td>
          <td class="text-center">
            <router-link
              :to="{
                path: '/chinaedit',
                query: { item: JSON.stringify(item) },
              }"
              >编辑</router-link
            ><span v-html="'\u00a0\u00a0\u00a0'"></span>
            <a class="delete" @click="handleClick(item.profile_id)">删除</a>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>
<script>
import axios from "axios";

export default {
  data() {
    return {
      resArr: [],
    };
  },
  mounted() {
    if (this.$store.state.chinaList.length === 0) {
      this.$store.dispatch("getChinaListAction");
    } else {
      // console.log('使用缓存数据')
    }
  },
  methods: {
    handleClick(profile_id) {
      // console.log({ profile_id });

      this.$confirm("此操作将永久删除该条目, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          // 剖面生境调查
          const profileHabitatPromise = new Promise((resolve, reject) => {
            axios
              .delete("/dataProfileHabitat/del", {
                params: { profile_id },
              })
              .then((res) => {
                if (res.data.code != 200) reject();
                resolve();
              });
          });

          const layerMorphologyPromise = new Promise((resolve, reject) => {
            axios
              .delete("/dataLayerMorphology/del", {
                params: { profile_id },
              })
              .then((res) => {
                if (res.data.code != 200) reject();
                resolve();
              });
          });

          const layerPhysicochemicalPromise = new Promise((resolve, reject) => {
            axios
              .delete("/dataLayerPhysicochemical/del", {
                params: { profile_id },
              })
              .then((res) => {
                if (res.data.code != 200) reject();
                resolve();
              });
          });

          // 基本信息
          Promise.all([
            profileHabitatPromise,
            layerMorphologyPromise,
            layerPhysicochemicalPromise,
          ])
            .then(() => {
              axios
                .delete("/data/delProfileBaseMessage", {
                  params: { profile_id },
                })
                .then((res) => {
                  console.log(res.data);
                  if (res.data.code != 200) {
                    this.$message.error("操作失败, 请检查服务器");
                    return;
                  }
                  this.$message({
                    message: "删除成功",
                    type: "success",
                  });
                  // 更新数据
                  // this.$store.dispatch("getChinaListAction");
                  this.$store.commit("deletechinaListMutation", profile_id);
                });
            })
            .catch(() => {
              this.$message.error("操作失败, 请检查服务器");
            });
        })
        .catch(() => {
          //   this.$message.info("已取消删除");
        });
    },
  },
};
</script>